import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 50 20" width="50" height="20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0527344 0H20.5329V3.35503H0.0527344V0ZM0.0527344 5.46478H20.5329V8.81982H0.0527344V5.46478ZM20.5329 12.8763L0.0527344 15.9435V12.5899L20.5329 9.52123V12.8763ZM0.0527344 16.6449H20.5329V20H0.0527344V16.6449Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.9472 5.15527H46.4764V5.8242H49.9472V5.15527ZM48.629 6.91081H47.7946V14.6998H48.629V6.91081ZM36.9323 10.796C36.9321 9.99506 37.1695 9.21208 37.6143 8.54606C38.0591 7.88004 38.6915 7.36091 39.4314 7.05432C40.1713 6.74773 40.9855 6.66746 41.7711 6.82366C42.5566 6.97986 43.2781 7.36552 43.8445 7.93185C44.4108 8.49818 44.7965 9.21974 44.9527 10.0053C45.1089 10.7908 45.0286 11.605 44.722 12.3449C44.4154 13.0848 43.8963 13.7172 43.2303 14.162C42.5642 14.6069 41.7813 14.8442 40.9804 14.844C40.4471 14.8501 39.9179 14.7496 39.424 14.5483C38.9301 14.3471 38.4814 14.0491 38.1043 13.672C37.7272 13.2949 37.4293 12.8462 37.228 12.3523C37.0267 11.8584 36.9262 11.3293 36.9323 10.796ZM44.1802 10.796C44.1802 8.96472 42.8004 7.54131 40.9804 7.54131C39.1603 7.54131 37.7815 8.96167 37.7815 10.796C37.7815 12.6303 39.1603 14.0496 40.9804 14.0496C42.8004 14.0496 44.1802 12.6272 44.1802 10.796ZM29.5566 14.1496L33.9612 7.6828H29.6896V6.89076H35.0758V7.44003L30.6712 13.9079H35.1966V14.6999H29.5566V14.1496Z"
      />
    </SvgIcon>
  )
}

export default Logo
